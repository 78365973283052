export enum ThemeModeEnum {
  AUTO,
  LIGHT,
  DARK,
}

export class Settings {
  static readonly darkStyleName = 'dark';

  constructor(
    public themeMode: ThemeModeEnum = ThemeModeEnum.AUTO,
    public cookiesAccepted = false,
  ) {}

  public static loadSettings() {
    const localSettings = localStorage.getItem('settings');
    if (localSettings) {
      return JSON.parse(localSettings) as Settings;
    }
    return new Settings();
  }

  public static saveSettings(settings: Settings) {
    localStorage.setItem('settings', JSON.stringify(settings));
  }
}
