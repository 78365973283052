/**
 * Devlink API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateMembershipBody { 
    /**
     * A URL to the JSON Schema for this object.
     */
    readonly $schema?: string;
    /**
     * Membership\'s price x 100
     */
    amount: number;
    /**
     * Membership\'s duration
     */
    duration: number;
    /**
     * Membership\'s max daily quick check products
     */
    max_daily_quick_check_products: number;
    /**
     * Membership\'s max quick check products
     */
    max_quick_check_products: number;
    /**
     * Membership\'s max subscribable products
     */
    max_subscribable_products: number;
    /**
     * Membership\'s UUID
     */
    membership_id: string;
    /**
     * Membership\'s name
     */
    name: UpdateMembershipBody.NameEnum;
    /**
     * Membership\'s renewability
     */
    renewable: boolean;
    /**
     * Membership\'s tier
     */
    tier: UpdateMembershipBody.TierEnum;
    /**
     * Membership\'s type
     */
    type: UpdateMembershipBody.TypeEnum;
}
export namespace UpdateMembershipBody {
    export type NameEnum = 'basic' | 'standard' | 'pro' | 'expert';
    export const NameEnum = {
        Basic: 'basic' as NameEnum,
        Standard: 'standard' as NameEnum,
        Pro: 'pro' as NameEnum,
        Expert: 'expert' as NameEnum
    };
    export type TierEnum = 1 | 2 | 3;
    export const TierEnum = {
        NUMBER_1: 1 as TierEnum,
        NUMBER_2: 2 as TierEnum,
        NUMBER_3: 3 as TierEnum
    };
    export type TypeEnum = 'subscription' | 'one_time_purchase';
    export const TypeEnum = {
        Subscription: 'subscription' as TypeEnum,
        OneTimePurchase: 'one_time_purchase' as TypeEnum
    };
}


