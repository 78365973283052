export const environment = {
  development: false,
  default_url: 'https://api.navitelogger.software',
  keycloak: {
    authority: 'https://auth.navitelogger.software',
    redirectUri: 'http://localhost:4200',
    postLogoutRedirectUri: 'http://localhost:4200/logout',
    realm: 'devlink',
    clientId: 'web',
  },
  POS_ID: '486232',
};
