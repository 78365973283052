import { effect, inject, Injectable, signal } from '@angular/core';
import { environment } from 'environment';
import { Configuration } from 'src/api';
import { Settings, ThemeModeEnum } from '../models/settings.class';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  public settingsSignal = signal<Settings>(Settings.loadSettings());
  public currentColorSignal = signal('');
  public apiBaseUrlSignal = signal(environment.default_url);

  private apiConfig = inject(Configuration);

  constructor() {
    effect(() => {
      this.apiConfig.basePath = this.apiBaseUrlSignal();
    });

    effect(() => {
      console.log('Settings changed: ' + JSON.stringify(this.settingsSignal()));
      Settings.saveSettings(this.settingsSignal());
    });

    effect(() => {
      // Remove all classes that start with 'color-scheme:'
      document.body.style.cssText = document.body.style.cssText.replace(
        /\bcolor-scheme:\s*\w+\b/g,
        '',
      );
      switch (this.settingsSignal().themeMode) {
        case ThemeModeEnum.DARK:
          document.body.style.cssText += 'color-scheme: light';
          break;
        case ThemeModeEnum.LIGHT:
          document.body.style.cssText += 'color-scheme: dark';
          break;
        case ThemeModeEnum.AUTO:
          document.body.style.cssText += 'color-scheme: light dark';
          break;
      }
    });
  }
}
