/**
 * Devlink API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Prices { 
    /**
     * A URL to the JSON Schema for this object.
     */
    readonly $schema?: string;
    /**
     * Price\'s creation date
     */
    created_at: string;
    /**
     * Price\'s currency
     */
    currency: Prices.CurrencyEnum;
    /**
     * Price\'s UUID
     */
    id: string;
    /**
     * Price\'s list
     */
    price_list: number[] | null;
    /**
     * Product\'s UUID
     */
    product_id: string;
    /**
     * Price\'s last update date
     */
    updated_at: string;
}
export namespace Prices {
    export type CurrencyEnum = 'USD' | 'EUR' | 'PLN';
    export const CurrencyEnum = {
        Usd: 'USD' as CurrencyEnum,
        Eur: 'EUR' as CurrencyEnum,
        Pln: 'PLN' as CurrencyEnum
    };
}


