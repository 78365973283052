/**
 * Devlink API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Order { 
    /**
     * A URL to the JSON Schema for this object.
     */
    readonly $schema?: string;
    /**
     * Order\'s creation date
     */
    created_at: string;
    /**
     * Order\'s currency
     */
    currency: Order.CurrencyEnum;
    /**
     * Order\'s UUID
     */
    id: string;
    /**
     * Membership\'s UUID
     */
    membership_id: string;
    /**
     * Order\'s mode
     */
    mode: Order.ModeEnum;
    /**
     * Payment provider
     */
    provider: Order.ProviderEnum;
    provider_specific_data?: object;
    /**
     * Order\'s status
     */
    status: Order.StatusEnum;
    /**
     * Order\'s last update date
     */
    updated_at: string;
    /**
     * User\'s email
     */
    user_email: string;
    /**
     * User\'s UUID
     */
    user_id: string;
    /**
     * User\'s name
     */
    user_name: string;
}
export namespace Order {
    export type CurrencyEnum = 'PLN' | 'USD' | 'EUR';
    export const CurrencyEnum = {
        Pln: 'PLN' as CurrencyEnum,
        Usd: 'USD' as CurrencyEnum,
        Eur: 'EUR' as CurrencyEnum
    };
    export type ModeEnum = 'automatic' | 'manual';
    export const ModeEnum = {
        Automatic: 'automatic' as ModeEnum,
        Manual: 'manual' as ModeEnum
    };
    export type ProviderEnum = 'payu' | 'przelewy24';
    export const ProviderEnum = {
        Payu: 'payu' as ProviderEnum,
        Przelewy24: 'przelewy24' as ProviderEnum
    };
    export type StatusEnum = 'pending' | 'completed' | 'failed';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Completed: 'completed' as StatusEnum,
        Failed: 'failed' as StatusEnum
    };
}


