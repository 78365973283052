/**
 * Devlink API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserMembership { 
    /**
     * A URL to the JSON Schema for this object.
     */
    readonly $schema?: string;
    /**
     * Membership\'s price x 100
     */
    amount: number;
    /**
     * User\'s membership purchase creation date
     */
    created_at: string;
    /**
     * Membership\'s remaining daily quick checks
     */
    daily_quick_checks_remaining: number;
    /**
     * Membership\'s duration
     */
    duration: number;
    /**
     * Membership\'s end date
     */
    end_date: string;
    /**
     * User\'s membership\'s UUID
     */
    id: string;
    /**
     * Membership\'s max daily quick check products
     */
    max_daily_quick_check_products: number;
    /**
     * Membership\'s max quick check products
     */
    max_quick_check_products: number;
    /**
     * Membership\'s max subscribable products
     */
    max_subscribable_products: number;
    /**
     * Membership\'s UUID
     */
    membership_id: string;
    /**
     * Membership\'s name
     */
    name: UserMembership.NameEnum;
    /**
     * Membership\'s remaining quick checks
     */
    quick_checks_remaining: number;
    /**
     * Membership\'s renewability
     */
    renewable: boolean;
    /**
     * Membership\'s start date
     */
    start_date: string;
    /**
     * Membership\'s tier
     */
    tier: UserMembership.TierEnum;
    /**
     * Membership\'s to renew
     */
    to_renew: boolean;
    /**
     * Membership\'s type
     */
    type: UserMembership.TypeEnum;
    /**
     * User\'s membership purchase last update date
     */
    updated_at: string;
    /**
     * User\'s email
     */
    user_email: string;
    /**
     * User\'s UUID
     */
    user_id: string;
    /**
     * User\'s name
     */
    user_name: string;
}
export namespace UserMembership {
    export type NameEnum = 'basic' | 'standard' | 'pro' | 'expert';
    export const NameEnum = {
        Basic: 'basic' as NameEnum,
        Standard: 'standard' as NameEnum,
        Pro: 'pro' as NameEnum,
        Expert: 'expert' as NameEnum
    };
    export type TierEnum = 1 | 2 | 3;
    export const TierEnum = {
        NUMBER_1: 1 as TierEnum,
        NUMBER_2: 2 as TierEnum,
        NUMBER_3: 3 as TierEnum
    };
    export type TypeEnum = 'subscription' | 'one_time_purchase';
    export const TypeEnum = {
        Subscription: 'subscription' as TypeEnum,
        OneTimePurchase: 'one_time_purchase' as TypeEnum
    };
}


